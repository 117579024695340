<template>
    <div>
        <div ref="render" class="results-render final-result" style="display:flex">
            <table style="font-size: 0.8rem;" v-if="displayMode=='standard'">
                <thead>
                    <tr class="grid-header">
                        <!--<th class="trophy" rowspan="2"></th>-->
                        <th rowspan="2" colspan="3">Compétiteur</th>
                        <th v-if="showSubTotals || showSubRanks" :colspan="(showSubTotals ? 1:0) + (showSubRanks ? 1:0)" :class='{"N/A": !hasPrecision}'>Précision</th>
                        <th v-if="showSubTotals || showSubRanks" :colspan="(showSubTotals ? 1:0) + (showSubRanks ? 1:0)" :class='{"N/A": !hasBallet}'>Ballet</th>
                        <th rowspan="2">Moyenne<br/>générale</th>
                        <th rowspan="2">Rg.</th>
                    </tr>
                    <tr class="grid-header">
                        <th v-if="showSubTotals" :class='{"N/A": !hasPrecision}'>Moy.</th>
                        <th v-if="showSubRanks" :class='{"N/A":!hasPrecision}'>Rg.</th>
                        <th v-if="showSubTotals" :class='{"N/A": !hasBallet}'>Moy.</th>
                        <th v-if="showSubRanks" :class='{"N/A": !hasBallet}'>Rg.</th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="result in rows" :key="result.id" :class="{'even': (result.isEffective && (result.rowIndex % 2 == 0)), 'odd': (result.isEffective && (result.rowIndex %2 !=0)), 'non-participant': !result.isEffective, 'open-mode-competitor': (result.isOpen == 1) }">
                        <td class="trophy" v-if="sortType=='precision_rank'">
                            <!-- Classement pour "Champion National de Précision" -->
                            <b-icon-trophy-fill v-show="true" v-if="!excludeOpens && result.isEffective && result.precision_rank < 4" :variant="result.precision_rank == 1 ? 'gold' : (result.precision_rank == 2 ? 'silver' : 'bronze')"/>
                            <b-icon-trophy-fill v-show="true" v-if="excludeOpens && result.isEffective && result.precision_federal_rank < 4" :variant="result.precision_federal_rank == 1 ? 'gold' : (result.precision_federal_rank == 2 ? 'silver' : 'bronze')"/>
                        </td>
                        <td class="trophy" v-else-if="sortType=='ballet_rank'">
                            <!-- Classement pour "Champion National de Ballet" -->
                            <b-icon-trophy-fill v-show="true" v-if="!excludeOpens && result.isEffective && result.ballet_rank < 4" :variant="result.ballet_rank == 1 ? 'gold' : (result.ballet_rank == 2 ? 'silver' : 'bronze')"/>
                            <b-icon-trophy-fill v-show="true" v-if="excludeOpens && result.isEffective && result.ballet_federal_rank < 4" :variant="result.ballet_federal_rank == 1 ? 'gold' : (result.ballet_federal_rank == 2 ? 'silver' : 'bronze')"/>
                        </td>
                        <td class="trophy" v-else>
                            <!-- Classement pour "Champion de France" -->
                            <b-icon-trophy-fill v-show="true" v-if="!excludeOpens && result.isEffective && result.rank < 4" :variant="result.rank == 1 ? 'gold' : (result.rank == 2 ? 'silver' : 'bronze')"/>
                            <b-icon-trophy-fill v-show="true" v-if="excludeOpens && result.isEffective && result.federal_rank < 4" :variant="result.federal_rank == 1 ? 'gold' : (result.federal_rank == 2 ? 'silver' : 'bronze')"/>
                        </td>

                        <td class="row-index">{{result.rowIndex}}</td>
                        <td class="name" :class="result.class">{{result.name}}</td>
                        <td v-if="result.isOutOfRanking" :class="result.outOfRankingClass" :colspan="result.colspan">{{ result.outOfRankingText }}</td>
                        
                        <td v-if="!result.isOutOfRanking && (showSubTotals || showSubRanks) && result.precision_note == null" class="N/A" :colspan="(showSubTotals ? 1:0) + (showSubRanks ? 1:0)"></td>
                        
                        <td v-if="!result.isOutOfRanking && showSubTotals && result.precision_note != null">{{ result.precision_note == null ? 'N/A' : arrondir(result.precision_note) }}</td>
                        <td v-if="!result.isOutOfRanking && showSubRanks && result.precision_note !=null">{{ result.precision_note == null ? '' : (excludeOpens ? result.precision_federal_rank : result.precision_rank) }}</td>
                        
                        
                        <td v-if="!result.isOutOfRanking && (showSubTotals || showSubRanks) && result.ballet_note == null" class="N/A" :colspan="(showSubTotals ? 1:0) + (showSubRanks ? 1:0)"></td>
                        
                        <td v-if="!result.isOutOfRanking && showSubTotals && result.ballet_note != null">{{ result.ballet_note == null ? 'N/A' : arrondir(result.ballet_note) }}</td>
                        <td v-if="!result.isOutOfRanking && showSubRanks && result.ballet_note !=null">{{ result.ballet_note == null ? '' : (excludeOpens ? result.ballet_federal_rank : result.ballet_rank) }}</td>


                        <td v-if="!result.isOutOfRanking">{{ (result.isAbsent || result.isForfeited || result.isWithdrawn) ? "" : arrondir(result.note) }}</td>
                        <td v-if="!result.isOutOfRanking"><b>{{ excludeOpens ? result.federal_rank : result.rank }}</b></td>
                    </tr>
                </tbody>
            </table>
            <div v-else style="background-color:white">
                <table style="font-size:11pt; font-family:'arial';">
                    <tr>
                        <td style="width:10.6cm;"><slot name="publishModeTitle">{{publishModeTitle}}</slot></td>
                        <td style="width:4.1cm; text-align:right;"><p class="table-row" style="font-style:italic; font-weight: bold; font-size:11pt; color:#A6A6A6;">{{rows.length}} Participant{{ rows.length > 1 ? "s": ""}}</p></td>
                    </tr>
                </table>
                <table class="publish" style="font-size:10pt; font-family:'arial'; line-height: 1em;">
                    <thead>
                        <tr style="font-weight:bolder; background-color: white;">
                            <th style="width:1.2cm"><p class="table-row">Rang</p></th>
                            <th style="width:6cm"><p class="table-row">Compétiteur</p></th>
                            <th style="width:1.7cm"><p class="table-row">Overall</p></th>
                            <th colspan="2"><p class="table-row">Précision</p></th>
                            <th colspan="2"><p class="table-row">Ballet</p></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="result in rows" :key="result.id" :class="{'even': (result.isEffective && (result.rowIndex % 2 == 0)), 'odd': (result.isEffective && (result.rowIndex %2 !=0)), 'non-participant': !result.isEffective}">
                            <td>
                                <p v-if="result.isOpen" class="table-row" style="color:#C65911; font-style:italic">{{ result.rank }}</p>
                                <p v-else-if="!result.isEffective" class="table-row" style="color:#808080; background-color: #dddddd; font-style:italic"></p>
                                <p v-else class="table-row">{{ result.rank }}</p>
                            </td>
                            <td class="name">
                                <p v-if="result.isOpen" class="table-row"  style="color:#C65911; font-style:italic">{{result.name}}</p>
                                <p v-else-if="!result.isEffective" class="table-row"  style="color:#808080; background-color: #dddddd; font-style:italic">{{ result.name }}</p>
                                <p v-else class="table-row">{{result.name}}</p>
                            </td>

                            <td v-if="!result.isEffective" colspan="5"><p class="table-row outOfRanking" style="color:#808080; background-color: #dddddd; font-style:italic">{{ result.outOfRankingText }}</p></td>
                            
                            <td v-if="result.isEffective">
                                <p v-if="result.isOpen" class="table-row" style="color:#C65911; font-style:italic">{{ arrondir(result.note) }}</p>
                                <p v-else class="table-row">{{ arrondir(result.note) }}</p>
                            </td>

                            <td style="width:1.7cm" v-if="result.isEffective">
                                <p v-if="result.isOpen" class="table-row" style="color:#C65911; font-style:italic">{{ result.precision_note == null ? '' : arrondir(result.precision_note) }}</p>
                                <p v-else class="table-row" >{{ result.precision_note == null ? '' : arrondir(result.precision_note) }}</p>
                            </td>
                            <td style="width:1.2cm" v-if="result.isEffective">
                                <p v-if="result.isOpen" class="table-row" style="color:#C65911; font-style:italic">{{ result.precision_note == null ? '' : result.precision_rank }}</p>
                                <p v-else class="table-row">{{ result.precision_note == null ? '' : result.precision_rank }}</p>
                            </td>
                            
                            <td style="width:1.7cm" v-if="result.isEffective">
                                <p v-if="result.isOpen" class="table-row" style="color:#C65911; font-style:italic">{{ result.ballet_note == null ? 'N/A' : arrondir(result.ballet_note) }}</p>
                                <p v-else class="table-row">{{ result.ballet_note == null ? 'N/A' : arrondir(result.ballet_note) }}</p>
                            </td>
                            <td style="width:1.2cm" v-if="result.isEffective">
                                <p v-if="result.isOpen" class="table-row" style="color:#C65911; font-style:italic">{{ result.ballet_note == null ? '' : result.ballet_rank }}</p>
                                <p v-else class="table-row">{{ result.ballet_note == null ? '' : result.ballet_rank }}</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</template>

<script>

export default{
    props: {
        Results: { type: Array, required: true},
        publishModeTitle: {type: String },
        includePrecisionResults: { type: Boolean, default: true},
        includeBalletResults: { type: Boolean, default: true},
        includeSubTotals: { type: Boolean, default: true},
        includeSubRanks: { type: Boolean, default: true},
        excludeOpens: { type: Boolean, default: false},
        sortType: { type: String, default: 'rank'},
        displayMode: { type: String, default: 'standard'}
    },
    computed:{
        hasPrecision(){
            return this.rows ? this.rows.findIndex(r => r.precision_note != null) >= 0 : false;
        },
        hasBallet(){
            return this.rows ? this.rows.findIndex(r => r.ballet_note != null) >= 0 : false;
        },
        showSubTotals(){
            return this.includeSubTotals;
        },
        showSubRanks(){
            return this.includeSubRanks;
        },
        rows(){
            var r = this.excludeOpens ? this.Results.filter(ri => ri.isOpen == 0) : this.Results.map(ri => ri);
            switch(this.sortType){
                case "rank":
                    r.sort((a,b) => this.compareRanks(a.note, b.note, a.rank, b.rank));
                    break;
                case "precision_rank":
                    r.sort((a,b) => this.compareRanks(a.precision_note, b.precision_note, a.precision_rank, b.precision_rank));
                    break;
                case "ballet_rank":
                    r.sort((a,b) => this.compareRanks(a.ballet_note, b.ballet_note, a.ballet_rank, b.ballet_rank));
                    break;
                case "alphabetical":
                    r.sort((a,b) => a.name.localeCompare(b.name));
                    break;
                case "subscription":
                    r.sort((a,b) => a.subscription_order - b.subscription_order);
                    break;
            }
            //console.log(r);
            return r.map((ri, i) => { ri.rowIndex = i+1; return ri; });
        }
    },
    methods:{
        compareRanks(aNote, bNote, aRank, bRank){
            var ret = 0;
           if(aNote != null && bNote != null)
                ret = aRank-bRank;
            else if(aNote != null)
                ret = -1;
            else if(bNote != null)
                ret = 0;
            //console.log('compateRanks', ret, aNote, bNote, aRank, bRank);
             return ret;
        }
    }
}
</script>

<style >
    .results-render{
        display:flex;
        justify-content: center;
    }
    .results-render > table {
        border-collapse: collapse;
        border: solid 2px var(--ffvlOrangePicto);
    }
    .results-render > table  td, .results-render > table th{
        border: solid 1px var(--ffvlOrangePicto);
        text-align: center;
    }
    table.results { margin-top: 10px;}
    .results{ border-collapse: collapse !important; }
    .results > tbody > tr > th { font-size: 0.95rem !important; orientation: landscape; border:darkgrey 1px solid !important; padding-left: 3px; padding-right: 3px; background-color:var(--ffvlBleuLogo) !important;}
    table.results > tbody > tr > td { font-size: 0.75rem !important; orientation: landscape; border:darkgrey 1px solid !important;}
    .results > tbody > tr > th.total{ background-color: var(--resultTotalHeader);}
    .results > tbody > tr > td.total{ background-color: var(--resultTotal);}
    .results > tbody > tr > th.rank{ background-color: var(--resultRankHeader);}
    .results > tbody > tr > td.rank{ background-color: var(--resultRank);}
    .results > tbody > tr > th.total, .results > tbody > tr > td.total { font-weight: bolder; color: var(--primary);}
    .results > tbody > tr > th.sorted{ background-color: var(--sortedHeader);}
    .results > tbody > tr > td.sorted{ background-color: var(--sortedCell);}

      tr.even td { background-color: beige;}
    tr.odd td{ background-color: #cdcdfd;}

    .trophy {
        background-color: transparent !important;
        border-left: none !important;
        border-right: solid 2px var(--ffvlOrangePicto) !important;
        padding-left: 5px;
        padding-right: 5px;
    }

    .row-index{ padding-left: 7px; padding-right: 7px;}

    .name{ min-width:150px !important; padding-left: 2px; text-align: left !important;}
 
    tr.non-participant td{
        background-color: #dddddd !important;
        color: #808080;
        font-style: italic;
    }


    tr.open-mode-competitor, tr.open-mode-competitor > td{
        color: #C65911 !important;
        font-style:italic;
    }
    tr.opencompetitor, tr.opencompetitor > p{
        color: #C65911 !important;
        font-style:italic;        
    }opencompetitor
    .publish { border-collapse: collapse;}
    .publish table, .publish tr > th, .publish tr > td{
        border: solid 1px black;
    }
    .publish th, .publish td{
        text-align: center;
        vertical-align: middle;
        padding-left: 1mm; padding-right:1mm;
    }
    .publish th.rank {
        min-width: 1.5cm;
        max-width: 1.5cm;
        width: 1.5cm;
    }
    .publish td.rank {
        min-width: 1.2cm;
        max-width: 1.2cm;
        width:1.2cm;
    }
    .publish th.competitor, .publish td.name {
        min-width: 4cm !important;
        max-width: 7cm !important;
        width: 6cm !important;
    }
    .publish td.name{
        text-align: left;
    }
    .publish th.overall, .publish td.overall, .publish th.note, .publish td.note{
        min-width: 2cm;
        max-width: 2cm;        
    }
    p.table-row{
        /*line-height: 1em;*/
        padding: 0.5mm;
    }

    @media screen and (max-width: 767px) and (orientation: portrait) {
        .results-render{
            orientation: landscape;
        }
    }
</style>
